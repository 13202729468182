import * as React from "react";
import { useState } from "react";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import { Card, CardContent } from "./ui/card";
import { FeaturedVideoDialog } from "./FeaturedVideoDialog";

export default function FeaturedVideos({ videos }) {
  const [featuredVideoUrl, setFeaturedVideoUrl] = useState(null);

  const plugin = React.useRef(
    Autoplay({ delay: 4000, stopOnInteraction: true })
  );

  return (
    <div className='w-full px-4 md:px-12'>
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        plugins={[plugin.current]}
        className='relative w-full'
      >
        <CarouselContent className='-ml-2 md:-ml-4'>
          {videos.map((video, index) => (
            <CarouselItem
              key={index}
              className='pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3'
            >
              <Card
                className='overflow-hidden transition-all duration-300 hover:shadow-lg cursor-pointer'
                onClick={() => setFeaturedVideoUrl(video.url)}
              >
                <CardContent className='p-0'>
                  <div className='aspect-video relative'>
                    <img
                      src={video.poster}
                      className='w-full h-full object-cover'
                      loading='lazy'
                      alt={`Featured video ${index + 1}`}
                    />
                    <div className='absolute inset-0 bg-gradient-to-t from-black/60 to-transparent' />
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className='hidden md:flex -left-4 lg:-left-8' />
        <CarouselNext className='hidden md:flex -right-4 lg:-right-8' />
      </Carousel>
      <FeaturedVideoDialog
        featuredVideoUrl={featuredVideoUrl}
        onClose={() => setFeaturedVideoUrl(null)}
      />
    </div>
  );
}
