import api from "./AxiosConfig";

export const getFrames = (text, modelId, firebaseUid, onFrameReceived) => {
  const ws = new WebSocket(
    `${process.env.REACT_APP_WS_API_URL}/v1/film-wizard/frames`
  );

  // Open WebSocket connection and send the initial payload to start generating frames
  ws.onopen = () => {
    ws.send(
      JSON.stringify({
        script: text,
        model_id: modelId,
        external_id: firebaseUid,
      })
    );
  };

  // Listen for messages containing frame data and pass each frame to the callback
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.status === "error") {
      console.error("Error in WebSocket:", data.detail);
      ws.close();
      return;
    }

    if (data.video && data.video.frames) {
      onFrameReceived(data.video); // Pass the entire video object to handle both initial and progressive updates
    }
  };

  // Handle any errors that may occur
  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  // Close the WebSocket connection when the component unmounts
  return () => {
    ws.close();
  };
};

export const getFrame = async (videoId, frameId, caption, modelId) => {
  try {
    const response = await api.put(
      `/v1/film-wizard/video/${videoId}/frames/${frameId}`,
      {
        new_caption: caption,
        model_id: modelId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetchVideoUrl:", error);
    return null;
  }
};
