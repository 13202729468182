import { AspectRatio } from "./ui/aspect-ratio";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

export function FeaturedVideoDialog({ featuredVideoUrl, onClose }) {
  return (
    <Dialog onOpenChange={onClose} modal={true} open={!!featuredVideoUrl}>
      <DialogContent className='lg:w-[900px]'>
        <DialogHeader>
          <DialogTitle>Featured Video</DialogTitle>
        </DialogHeader>
        <AspectRatio ratio={16 / 9}>
          <video
            controls
            autoPlay
            style={{
              maxHeight: "800px",
              maxWidth: "100%",
              margin: "0 auto",
              borderRadius: "4px",
            }}
          >
            <source src={featuredVideoUrl} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </AspectRatio>
      </DialogContent>
    </Dialog>
  );
}
