import React, { useEffect, useState } from "react";
import { loginAndStoreUser, logoutUser } from "../services/UserService"; // Assuming you have these functions in UserService.js
import { VideoIcon } from "lucide-react";
import { Button } from "./ui/button";
const Header = ({ user }) => {
  const [photoURL, setPhotoURL] = useState(user?.photoURL || "");

  useEffect(() => {
    // Cache the photoURL once it's retrieved to avoid multiple requests
    if (!photoURL && user && user.photoURL) {
      setPhotoURL(user.photoURL);
    }
  }, [user, photoURL]);

  const handleLogin = async () => {
    try {
      await loginAndStoreUser(); // Trigger login process
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser(); // Trigger logout process
      window.location.reload(); // Optionally reload to clear state
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <header className='py-2 bg-[#463ca7] text-white text-4xl font-extrabold'>
      <div className='container mx-auto flex justify-between items-center'>
        <div className='flex items-center space-x-3'>
          <VideoIcon className='h-6 w-6' />
          <span className='text-xl font-bold tracking-tight'>REELFY.AI</span>
        </div>
        {user ? (
          <div className='flex items-center'>
            <img
              src={photoURL}
              alt={user.displayName}
              className='w-10 h-10 rounded-full mr-2'
              onError={(e) => (e.target.src = "/fallback_user_avatar.png")} // Fallback image
            />
            <span className='mr-4 text-sm'>
              {user.displayName.split(" ")[0]}
            </span>
            <Button
              onClick={handleLogout}
              className='bg-white text-[#463ca7]  rounded-sm'
              size='sm'
            >
              Logout
            </Button>
          </div>
        ) : (
          <Button
            variant='secondary'
            className='bg-white text-[#463ca7] hover:bg-gray-100 font-medium'
            onClick={handleLogin}
          >
            Login
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
