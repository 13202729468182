import React from "react";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Camera, Clapperboard, Paintbrush, Sparkles } from "lucide-react";
import { ProviderBadge } from "../ui/provider-badge";

const icons = {
  Cinematic: <Clapperboard className='h-4 w-4' />,
  Photorealistic: <Camera className='h-4 w-4' />,
  Anime: <Sparkles className='h-4 w-4' />,
  Artsy: <Paintbrush className='h-4 w-4' />,
};

const AestheticsSelector = ({ aesthetics, onSelect }) => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
      {aesthetics.map((aesthetic) => (
        <Button
          key={aesthetic.id}
          variant='outline'
          className={cn(
            "group h-auto p-0 relative overflow-hidden hover:border-primary/50"
            // selected === aesthetic.id && "ring-2 ring-primary ring-offset-2"
          )}
          onClick={() => onSelect(aesthetic)}
        >
          <div className='absolute inset-0 bg-gradient-to-t from-black/60 via-black/0' />
          <img
            src={aesthetic.provider.model_sample_image_url}
            alt={aesthetic.name}
            className='w-full aspect-[4/3] object-cover'
          />

          {/* Provider Badge - Top Right */}
          <div className='absolute top-3 right-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200'>
            <ProviderBadge
              name={aesthetic.provider.name}
              logo={aesthetic.provider.logo_url}
            />
          </div>

          {/* Title and Icon - Bottom */}
          <div className='absolute bottom-0 left-0 right-0 p-4'>
            <div className='flex items-center gap-2 text-white'>
              {icons[aesthetic.name]}
              <span className='font-medium'>{aesthetic.name}</span>
            </div>
          </div>
        </Button>
      ))}
    </div>
  );
};

export default AestheticsSelector;
