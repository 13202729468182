import { Skeleton } from "../ui/skeleton";

export default function AestheticSkeleton() {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className='relative overflow-hidden border rounded-lg bg-card'
        >
          <Skeleton className='w-full aspect-[4/3]' />

          {/* Provider Badge Skeleton */}
          <div className='absolute top-3 right-3'>
            <Skeleton className='h-6 w-24' />
          </div>

          {/* Title Skeleton */}
          <div className='absolute bottom-0 left-0 right-0 p-4'>
            <div className='flex items-center gap-2'>
              <Skeleton className='h-4 w-4' />
              <Skeleton className='h-4 w-24' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
