import { Edit2, Loader2, Save, Wand2 } from "lucide-react";
import { useState } from "react";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

function FrameCard({
  lastTake,
  frame,
  index,
  onEditSave,
  onReImagine,
  isLoadingFrame,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCaption, setEditedCaption] = useState(lastTake.caption);
  const isLoading = isLoadingFrame || !lastTake.url;

  return (
    <div className='relative'>
      <div
        className={cn(
          "group relative overflow-hidden rounded-lg border bg-card w-[320px] shrink-0 transition-all duration-300 hover:shadow-lg"
        )}
      >
        <div className='aspect-[4/3] relative'>
          {lastTake.url && !isLoadingFrame ? (
            <>
              <img
                src={lastTake.url}
                alt={`Frame ${index + 1}`}
                className='object-cover w-full h-full transition-transform duration-300 group-hover:scale-105'
                loading='lazy'
              />
            </>
          ) : (
            <div className='absolute inset-0 bg-black/50 flex items-center justify-center backdrop-blur-sm'>
              <div className='flex flex-col items-center gap-2'>
                <Loader2 className='h-6 w-6 animate-spin text-white' />
                <p className='text-sm text-white font-medium'>
                  {isLoadingFrame ? "Re-imagining..." : "Generating..."}
                </p>
              </div>
            </div>
          )}
          <div className='absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-80 transition-opacity duration-300 group-hover:opacity-100' />
        </div>
        {isEditing ? (
          <div className='absolute bottom-4 left-4 right-4 flex gap-2'>
            <Input
              value={editedCaption}
              onChange={(e) => setEditedCaption(e.target.value)}
              className='bg-black/50 text-white border-none rounded-lg'
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsEditing(false);
                }
              }}
            />
            <Button
              size='icon'
              variant='secondary'
              onClick={() => {
                setIsEditing(false);
                onEditSave(editedCaption);
              }}
              className='shrink-0 bg-black/50 hover:bg-black/70'
            >
              <Save className='h-4 w-4 text-white' />
            </Button>
          </div>
        ) : (
          <div className='absolute bottom-4 left-4 right-4 flex justify-between items-center'>
            <p className='text-sm text-white flex-1 line-clamp-2 pr-2'>
              {lastTake.caption}
            </p>
            {!isLoading && (
              <div className='flex gap-2'>
                <Button
                  size='icon'
                  variant='ghost'
                  className='text-white bg-inherit opacity-0 group-hover:opacity-100 transition-opacity shrink-0 hover:bg-black/20 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-inherit disabled:text-white'
                  onClick={() => setIsEditing(true)}
                  title='Edit Caption'
                  disabled={isLoading}
                >
                  <Edit2 className='h-4 w-4' />
                </Button>
                <Button
                  size='icon'
                  variant='ghost'
                  className='text-white bg-inherit opacity-0 group-hover:opacity-100 transition-opacity shrink-0 hover:bg-black/20 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-inherit disabled:text-white'
                  onClick={onReImagine}
                  title='Reimagine'
                  disabled={isLoading}
                >
                  <Wand2 className='h-4 w-4' />
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FrameCard;
