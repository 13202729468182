import React, { useState, useEffect } from "react";
import { getFrame } from "../../services/ImageService";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import FrameCard from "./FrameCard";
import { Button } from "../ui/button";
import { ArrowLeft, Check } from "lucide-react";

const FrameViewer = ({
  frames,
  onConfirm,
  onBack,
  videoId,
  modelId,
  isWebSocket,
}) => {
  const [frameData, setFrameData] = useState(frames);

  const [loadingFrames, setLoadingFrames] = useState(
    new Array(frames.length).fill(false)
  ); // Array to track loading state

  useEffect(() => {
    if (!isWebSocket) {
      setFrameData(frames);
    }
  }, [frames, isWebSocket]);

  useEffect(() => {
    if (isWebSocket) {
      // Initialize or update frames based on unique `position`
      setFrameData((prevData) =>
        frames.map((newFrame) => {
          const existingFrame = prevData.find(
            (frame) => frame.position === newFrame.position
          );
          if (existingFrame) {
            // Merge updated frame data
            return {
              ...existingFrame,
              ...newFrame,
            };
          }
          return newFrame; // Add new frame if not present
        })
      );
    }
  }, [frames, isWebSocket]);

  const handleEditSave = (index, value) => {
    const newFrameData = [...frameData];
    const lastTakeIndex = (newFrameData[index]?.takes || []).length - 1;
    if (lastTakeIndex >= 0) {
      newFrameData[index].takes[lastTakeIndex].caption = value; // Update the caption for the last take
    }
    setFrameData(newFrameData);
  };

  const handleReImagine = async (index) => {
    const newLoadingFrames = [...loadingFrames];
    newLoadingFrames[index] = true; // Set loading state to true
    setLoadingFrames(newLoadingFrames);

    const frame = frameData[index];
    const lastTakeIndex = (frame.takes || []).length - 1;
    const currentCaption = frame.takes?.[lastTakeIndex]?.caption || ""; // Use the caption from the last take
    const newFrameResponse = await getFrame(
      videoId,
      frame.id,
      currentCaption,
      modelId
    );

    if (newFrameResponse?.frame) {
      const newTake = {
        id: newFrameResponse.frame.takes[0].id, // Take ID from backend
        url: newFrameResponse.frame.takes[0].url, // URL from backend
        caption: currentCaption,
        created_at: new Date().toISOString(), // Add a timestamp
      };

      const newFrameData = [...frameData];
      if (newFrameData[index]) {
        // Append the new take to the `takes` array
        newFrameData[index].takes = [
          ...(newFrameData[index].takes || []),
          newTake,
        ];
      }

      setFrameData(newFrameData);
    }

    newLoadingFrames[index] = false; // Set loading state to false
    setLoadingFrames(newLoadingFrames);
  };

  return (
    <div className='w-full space-y-8'>
      <ScrollArea className='w-full whitespace-nowrap rounded-xl border bg-card/50 backdrop-blur supports-[backdrop-filter]:bg-card/50'>
        <div className='flex w-max space-x-6 p-6'>
          {frameData.map((frame, index) => {
            const lastTakeIndex = (frame.takes || []).length - 1; // Get the last take index
            const lastTake = frame.takes?.[lastTakeIndex] || {}; // Fallback to an empty object

            return (
              <FrameCard
                key={index}
                frame={frame}
                index={index}
                lastTake={lastTake}
                onEditSave={(value) => handleEditSave(index, value)}
                isLoadingFrame={loadingFrames[index]}
                onReImagine={() => handleReImagine(index)}
              />
            );
          })}
        </div>
        <ScrollBar orientation='horizontal' className='hidden sm:flex' />
      </ScrollArea>

      <div className='flex justify-between items-center gap-4 px-1'>
        <Button
          variant='secondary'
          className='gap-2 relative px-8'
          onClick={onBack}
        >
          <ArrowLeft className='h-4 w-4' />
          <span>Change Aesthetic</span>
        </Button>
        <Button className='gap-2 px-8' onClick={onConfirm}>
          <Check className='h-4 w-4' />
          <span>Confirm Frames</span>
        </Button>
      </div>
    </div>
  );
};

export default FrameViewer;
