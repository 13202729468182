import React, { useState, useRef, useEffect } from "react";

import Wizard from "../components/Wizard";
import UserVideoList from "../components/UserVideoList";

import MobileMessage from "../components/MobileMessage"; // Import the MobileMessage component
import { BorderBeam } from "../components/magicui/border-beam";
import TermsModal from "../components/TermsModal"; // Import the TermsModal component
import Header from "../components/Header"; // Import new Header
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/UserService"; // Firebase auth
import { Textarea } from "../components/ui/textarea";
import { Button } from "../components/ui/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import FeaturedVideos from "../components/FeaturedVideos";

function LandingPage() {
  const [user, setUser] = useState(null);
  const [text, setText] = useState("");
  const [validatedText, setValidatedText] = useState(""); // New state to hold validated script
  const [error, setError] = useState("");
  const isMobile = window.innerWidth <= 768;
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false); // State for terms modal
  const closeTermsModal = () => setIsTermsModalOpen(false); // Close terms modal
  const [wizardKey, setWizardKey] = useState(0); // Key to force re-render Wizard component
  const [selectedVideo, setSelectedVideo] = useState(null);
  const wizardRef = useRef(null);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Set logged-in user
      } else {
        setUser(null); // No user is logged in
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  const featuredVideos = [
    {
      id: 1,
      url: "https://storage.googleapis.com/reelfy-media-assets/featured-clips/The%20enhancement%20dilemma.mp4",
      poster:
        "https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/666332d5b8800e9dbf76addf/images/1.png",
    },
    {
      id: 2,
      url: "https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668aecaa784c39a6c54c51a/final_clip.mp4",
      poster:
        "https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668aecaa784c39a6c54c51a/images/1.png",
    },
    {
      id: 3,
      url: "https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668b42ba784c39a6c54c51e/final_clip.mp4",
      poster:
        "https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668b42ba784c39a6c54c51e/images/1.png",
    },
  ];

  // Sample stories
  const sampleStories = [
    {
      title: "Morning Adventure",
      description:
        "Early morning at a small village. The sun peeks over the horizon, casting golden light on the thatched roofs of the cottages. A young girl named Lily steps outside, her backpack ready for an adventure.",
    },
    {
      title: "Garden Discovery",
      description:
        "Tom discovers a small pond in the heart of the garden. The water reflects the sky above, and lily pads float gently on the surface. Dragonflies buzz around, their wings glinting in the sunlight.",
    },
    {
      title: "Carnival Night",
      description:
        "Bright lights illuminate the carnival grounds. Children and families walk between booths, their faces lit with excitement. The Ferris wheel turns slowly against the night sky.",
    },
  ];

  // Function to load story into main text area
  const loadStory = (story) => {
    setText(story);
  };

  const handleSubmitScript = () => {
    if (!text.trim()) {
      setError("Do not forget to write down your script!");
      return;
    }
    setError("");
    setValidatedText(text);
    setWizardKey((prevKey) => prevKey + 1); // Change the key to force re-render
    if (wizardRef.current) {
      wizardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleUserVideoSelect = (video) => {
    setSelectedVideo({ ...video, key: Date.now() }); // Add unique key
  };

  console.log(wizardKey, validatedText, selectedVideo);

  return (
    <div className='landing-page'>
      {isMobile && <MobileMessage />}

      {/* Header */}
      <Header user={user} />

      <div className='flex w-full'>
        {/* Sidebar for user videos */}
        {user && (
          <aside className='w-1/4 min-h-[600px] p-4 bg-gray-200 rounded-lg shadow-lg mr-1'>
            <UserVideoList user={user} onVideoSelect={handleUserVideoSelect} />
          </aside>
        )}

        {/* Main Content Area */}
        <main className={`${user ? "w-3/4" : "w-full"}  flex-grow`}>
          <section className='py-12 md:py-16'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-2xl mx-auto text-center space-y-4'>
                <h1 className='text-3xl md:text-4xl font-bold tracking-tight'>
                  Script to Video
                </h1>
                <p className='text-lg text-muted-foreground'>
                  Let's amplify together your message, drop that script in the
                  box, and let Reelfy create the best audiovisual match for your
                  craft.
                </p>
              </div>
              <div className='max-w-2xl mx-auto text-center mt-10'>
                <div className='relative-container'>
                  <Textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    rows='5'
                    placeholder='Write your script here...'
                    className='script-input min-h-[200px] p-4 rounded-lg resize-y'
                  />
                  <BorderBeam borderWidth='2.5' />
                </div>
                {error && (
                  <p className='text-red-500 mt-2 text-center'>{error}</p>
                )}
                <div className='space-y-4'>
                  <Button
                    type='submit'
                    size='lg'
                    className='w-full text-lg font-medium bg-[#f4a825] hover:bg-[#d38f20]'
                    onClick={handleSubmitScript}
                    variant='secondary'
                  >
                    Generate video
                  </Button>
                  <p className='text-sm text-center text-muted-foreground'>
                    By generating a video, you agree to our{" "}
                    <span
                      className='text-[#463ca7] cursor-pointer'
                      onClick={() => setIsTermsModalOpen(true)}
                    >
                      Terms of Service
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Wizard Container */}
          <Wizard
            key={wizardKey}
            text={validatedText}
            selectedVideo={selectedVideo}
          />

          <section className='py-16 bg-muted'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-6xl mx-auto space-y-8'>
                <h2 className='text-2xl md:text-3xl font-semibold text-center'>
                  Looking for inspiration?
                </h2>
                <div className='grid gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                  {sampleStories.map((story, index) => (
                    <Card
                      key={index}
                      className='transition-all duration-200 hover:shadow-lg cursor-pointer'
                      onClick={() => loadStory(story.description)}
                    >
                      <CardHeader>
                        <CardTitle className='text-xl mb-3'>
                          {story.title}
                        </CardTitle>
                        <CardDescription className='text-base leading-relaxed'>
                          {story.description}
                        </CardDescription>
                      </CardHeader>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {/* This is the featured videos container */}
          <section className='py-16'>
            <div className='container px-4 mx-auto'>
              <div className='max-w-6xl mx-auto space-y-8'>
                <h2 className='text-2xl md:text-3xl font-semibold text-center'>
                  Featured Videos
                </h2>
                <FeaturedVideos videos={featuredVideos} />
              </div>
            </div>
          </section>
        </main>
      </div>
      {/* Roadmap Section */}
      <section className='image-section mt-12'>
        <img
          className='default-image responsive-image'
          src='/mission_vision_roadmap.png'
          alt='Mission, Vision, and Roadmap'
        />
        <img
          className='mobile-image responsive-image'
          src='/1.png'
          alt='Mission - Mobile View'
        />
        <img
          className='mobile-image responsive-image'
          src='/2.png'
          alt='Vision - Mobile View'
        />
      </section>

      {/* Footer */}
      <footer className='footer py-6 bg-[#463ca7] text-white text-center text-base font-normal'>
        <p>&copy; 2024 Reelfy. All rights reserved.</p>
      </footer>

      <TermsModal isOpen={isTermsModalOpen} onClose={closeTermsModal} />
    </div>
  );
}

export default LandingPage;
