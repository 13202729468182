import { cn } from "../../lib/utils";
import React from "react";

interface ProviderBadgeProps {
  name: string;
  logo: string;
  className?: string;
}

export function ProviderBadge({ name, logo, className }: ProviderBadgeProps) {
  return (
    <div
      className={cn(
        "flex items-center gap-2 bg-black/40 backdrop-blur-sm rounded-full px-3 py-1.5",
        "transition-all duration-200 hover:bg-black/60",
        className
      )}
    >
      <span className='text-xs font-medium text-white'>Powered by</span>
      <img src={logo} alt={name} className='h-4 w-auto' />
    </div>
  );
}
